.main__home {
  padding: 30px 15px;
}

.profile__head {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  margin-bottom: 100px;
}

.profile__picture .img {
  width: 200px;
  height: 200px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.profile__socials {
  margin-left: 10px;
  &__column {
    display: flex;
    flex-direction: column;

    a {
      display: inline-block;
      margin-bottom: 5px;

      &:hover,
      &:focus,
      &:active {
        path.fill {
          fill: var(--highlight-2);
        }
      }
    }

    & > a:last-child {
      margin-bottom: 0;
    }
  }
}

.profile__nametag,
.profile__nametag__shadow {
  position: absolute;
  top: calc(100% - 20px);
  left: 20px;
  z-index: 3999;

  &__container {
    padding: 0 2px;
  }

  h2 {
    font-size: 21px;
    font-style: italic;
    line-height: 1.2em;
  }

  p {
    font-size: 14px;
  }
}

.profile__nametag__shadow {
  left: 35px;
  top: calc(100% - 10px);
  z-index: unset;
}

.profile__body {
  position: relative;
  h3 {
    @include heading-regular;
    font-style: italic;
    text-transform: uppercase;
    font-size: 17px;
    margin-bottom: 7px;
  }

  p {
    font-size: 14px;
  }

  .profile__bio {
    &__container {
      padding: 10px;
    }
  }
}

.bio__location {
  position: absolute;
  z-index: 500;
  right: 12.5%;
  bottom: calc(100% - 10px);
  p {
    margin-left: 7px;
    padding-right: 10px;
    white-space: nowrap;
  }
  span {
    display: flex;
  }
}
