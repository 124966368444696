// ===========================
// NORMALIZE
// ===========================

@import "./normalize/normalize";
@import "./normalize/resets";

// ===========================
// VARIABLES, MIXINS
// ===========================

@import "./variables/variables";
@import "./variables/typography";

// ===========================
// COMPONENTS
// ===========================

@import "./components/grid-bg-overlay";
@import "./components/styled-border";
@import "./components/styled-icons";
@import "./components/preloader";
@import "./components/animations";
@import "./components/pagecontrols";
@import "./components/styled-links";
@import "./components/modal-wall";
@import "./components/gallery";

// ===========================
// PAGES
// ===========================

@import "./pages/home";
@import "./pages/woah";

// ===========================
// APP
// ===========================
html,
body {
  // height: var(--vh);
  // width: var(--vw);
  // overflow: hidden;
  /* width */
  z-index: -1;
}

.App {
  // height: 100%;
  position: relative;
  // overflow-y: auto;
  // overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--text-2);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--text-3);
    border-radius: 5px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--highlight-1);
  }
}

main {
  height: 100%;
  width: 100%;
  max-width: 500px;
  padding: 30px 15px;
  margin: auto;
  overflow-x: hidden;
  overflow-y: hidden;
}
