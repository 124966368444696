.gallery {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4999;
  display: flex;
  flex-direction: column;
  transition: opacity 0.2s ease;

  &.inactive {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0s ease;

    a {
      display: none !important;
    }
  }

  &__header {
    padding: 10px;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 4999;

    &__wrap {
      max-width: 500px;
      margin: auto;
      display: flex;
      justify-content: space-between;
    }

    button {
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      padding: 7px;

      i {
        margin: 0;
        padding: 0;
        color: var(--text-1);
      }
    }
  }

  &__body__container {
    height: 100%;
    overflow-y: auto;
  }

  &__body {
    padding: 10px;
    padding-top: 63px;
    max-width: 500px;
    margin: auto;
  }

  &__item__container {
    width: 100%;

    .gallery__item {
      margin-bottom: 30px;
    }

    &:nth-child(odd) {
      padding-right: 40px;
    }

    &:nth-child(even) {
      padding-left: 40px;
    }
  }

  &__image__container {
    width: 100%;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      vertical-align: middle;
    }
  }

  &__image__caption {
    margin-top: 5px;

    p {
      font-size: 14px;
    }
  }
}
