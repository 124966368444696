.modalwall {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4999;
  transition: 0.2 ease;
  display: flex;
  justify-content: space-around;
  align-items: center;

  &.inactive {
    opacity: 0;
  }

  &__content button {
    width: 300px;
    height: 300px;
    display: block;
    background: none;
    border: none;
    outline: none;
  }

  &__content h2 {
    margin-bottom: 200px;
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
    font-style: italic;
    color: var(--elevation-1);
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    position: relative;
    line-height: 1em;

    &::before {
      content: "Continue to Woah";
      position: absolute;
      top: 2px;
      left: 2px;
      width: 100%;
      height: 100%;
      color: var(--text-1);
      -webkit-text-fill-color: var(
        --text-1
      ); /* Will override color (regardless of order) */
      z-index: -1;
    }
  }
}

.pulsating-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;

  &:before {
    content: "";
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 50%;
    background-color: var(--highlight-1);
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.7);
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }

  span {
    position: absolute;
    top: 50%;
    left: calc(50% + 2px);
    transform: translateX(-50%) translateY(-50%);
    z-index: 4800;

    i {
      padding: 0;
      margin: auto;
      font-size: 30px;
      color: var(--elevation-1);
      text-shadow: 0px 0px 6px var(--text-3);
    }
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.98);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.98);
  }
}
