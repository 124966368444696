.preloader {
  position: fixed;
  height: var(--vh);
  width: var(--vw);
  background: var(--text-1);
  z-index: 5000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: 1s ease;
  opacity: 1;

  &__wrapper {
    width: 200px;
    height: 150px;
    display: flex;

    justify-content: center;
    align-items: center;
  }

  &__label p {
    color: var(--elevation-1);
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  &.inactive {
    opacity: 0;
  }
}

/* ==== Effect 4 ==== */

.line4 {
  height: 100px;
  margin: 0 3px;
  width: 2px;
  background-image: linear-gradient(
    180deg,
    var(--highlight-1) 0%,
    var(--highlight-3) 50%,
    var(--highlight-2) 100%
  );
  transform-origin: top;
  animation: dance4 1.4s ease-in-out infinite;
}

.line4:nth-child(2) {
  animation-delay: 0.1s;
}
.line4:nth-child(3) {
  animation-delay: 0.2s;
}
.line4:nth-child(4) {
  animation-delay: 0.3s;
}
.line4:nth-child(5) {
  animation-delay: 0.4s;
}
.line4:nth-child(6) {
  animation-delay: 0.5s;
}
.line4:nth-child(7) {
  animation-delay: 0.6s;
}
.line4:nth-child(8) {
  animation-delay: 0.7s;
}
.line4:nth-child(9) {
  animation-delay: 0.8s;
}
.line4:nth-child(10) {
  animation-delay: 0.9s;
}
.line4:nth-child(11) {
  animation-delay: 1s;
}
.line4:nth-child(12) {
  animation-delay: 1.1s;
}
.line4:nth-child(13) {
  animation-delay: 1.2s;
}
.line4:nth-child(14) {
  animation-delay: 1.3s;
}
.line4:nth-child(15) {
  animation-delay: 1.4s;
}

@keyframes dance4 {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.3);
  }
}
