.links {
  &__title {
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
    font-style: italic;
    color: var(--elevation-1);
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    position: relative;
    margin: 15px 0 !important;

    &::before {
      content: "Featured";
      position: absolute;
      top: 2px;
      left: 2px;
      width: 100%;
      height: 100%;
      color: var(--text-1);
      -webkit-text-fill-color: var(
        --text-1
      ); /* Will override color (regardless of order) */
      z-index: -1;
    }
  }

  &__thumbnail .img {
    width: 100px;
    height: 100px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  li.disabled {
    // opacity: 0.5;
    filter: grayscale(100%);
  }

  a,
  button {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
    padding: 0;

    .links__meta {
      margin-left: 10px;
      position: relative;

      &__container {
        width: 100%;
        h2 {
          font-size: 21px;
          font-style: italic;
          line-height: 1.1em;
          margin-bottom: 5px;
        }

        p {
          font-size: 12px;
        }

        .styledborder__container {
          padding-bottom: 10px;
        }
      }

      &__tag {
        position: absolute;
        right: 10px;
        top: calc(100% - 20px);
      }

      &__tag .styledborder__container,
      &__tag .styledborder__body {
        background: var(--highlight-1);
      }

      &__tag .styledborder__body {
        margin: 4px 7px;
      }

      &__tag p {
        text-transform: uppercase;
        font-size: 12px;
      }
    }
  }

  .links__meta__container__content {
    position: relative;
  }

  .link__icon {
    position: absolute;
    width: 25px;
    height: 25px;
    right: 0;
    top: 0;

    i {
      color: var(--text-1);
    }

    svg {
      fill: var(--text-1);
      width: 100%;
      height: 100%;
    }
  }
}

.coming__soon {
  width: 100%;
  margin-top: 100px;

  h2 {
    text-align: center;
    font-size: 21px;
  }

  p {
    text-align: center;
    font-size: 14px;
    padding: 0 10px;
    max-width: 95%;
    margin: auto;
  }
}

.no-thumb {
  margin-bottom: 20px !important;

  .styledborder__container, .styledborder__body {
    background: var(--highlight-1);
  }

  h2, p {
    text-align: center;
  }
}

.no-thumb .links__meta__tag {
  left: 50%;
  right: unset;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-around;
  top: calc(100% - 15px) !important;
  
  .styledborder__container, .styledborder__body {
    background: #1a1a1a !important;
 

    p {
      color: var(--elevation-1) !important;
    }
  }
  
}