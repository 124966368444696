.profile__nametag.player__controls,
.profile__nametag__shadow.player__controls {
  position: absolute;
  top: calc(100% - 20px);
  left: 25%;
}

.profile__nametag__shadow.player__controls {
  left: calc(25% + 10px);
  top: calc(100% - 10px);
}

.profile__nametag.songtag {
  top: calc(0% - 10px);

  p {
    font-size: 12px;
  }
}

.player__controls {
  &__actions {
    display: flex;
  }
  button {
    background: none;
    border: none;
    padding: 3px 6px;
    cursor: pointer;

    i {
      display: flex;
      justify-content: space-around;
      margin: auto;
      color: var(--text-1);
      transition: 0.2s ease;
    }

    &:hover {
      i {
        color: #ccc;
      }
    }
  }
}

.profile__socials__column.discography {
  display: flex;
  flex-direction: row;

  .discography__icons {
    display: flex;
    flex-direction: column;
    margin-right: 4px;
    width: 30px;
  }

  .discography__prompt {
    display: flex;
    flex-direction: row-reverse;
    min-width: 35px;
    h2,
    p {
      text-orientation: mixed;
      writing-mode: vertical-rl;
    }

    h2 {
      text-transform: uppercase;
      font-style: italic;
      letter-spacing: 0.02em;
      line-height: 1em;
      margin-left: 3px;
    }

    p {
      font-size: 12px;
      line-height: 1em;
      margin-left: 3px;
      width: 27px;
    }
  }
}

.profile__picture.audio__player .img {
  position: relative;
  height: 200px;
  width: 200px;

  .promo {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: black, $alpha: 0.75);
    display: flex;
    align-items: center;
    transition: 0.2s ease;
    opacity: 0;
    visibility: none;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    &__container {
      width: 100%;
      h2 {
        text-transform: uppercase;
        font-style: italic;
        color: var(--elevation-1);
        letter-spacing: 0.08em;
        text-align: center;
        text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.36);
        font-size: 21px;
        line-height: 1em;
        margin-bottom: 4px;
      }

      a {
        @include body-regular;
        text-decoration: underline;
        color: var(--highlight-1);
        text-align: center;
        display: block;
        text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.36);

        i {
          text-decoration: none;
          margin-left: 3px;
          opacity: 0.5;
        }

        &:hover,
        &:active,
        &:focus {
          i {
            opacity: 1;
          }
        }
      }
    }
  }
}

.audio__timeline__wrap {
  position: absolute;
  left: 50%;
  top: calc(100% - 60px);
  z-index: 3999;
}

.audio__timeline {
  transform: translateX(-50%);

  &__cont {
    display: flex;
    align-items: center;
  }

  span {
    @include body-regular;
    font-size: 12px;
    width: 30px;
    text-align: center;
  }

  &__progress {
    width: 100px;
    height: 5px;
    background: rgba($color: black, $alpha: 0.25);
    margin-left: 3px;
    margin-right: 3px;
    position: relative;
  }

  &__bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--highlight-4);
  }
}
