svg.styled__icon {
  fill: none;
  overflow: visible !important;

  path {
    overflow: visible !important;
  }

  path.fill {
    fill: var(--highlight-1);
    stroke-width: 1px;
    stroke: black;
    transition: 0.2s ease;
  }

  path.shadow {
    fill: var(--text-1);
    transform: translate(1px, 1px) scale(1.025);
  }
}
