:root {
  // SHADOWS
  --shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.115);
  --text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.55);
  // BACKGROUNDS
  --elevation-1: #ffffff;
  --elevation-2: #f7f7f7;
  --elevation-3: #efefef;
  --elevation-4: #eeeeee;
  --elevation-5: #dddddd;
  --elevation-6: #cccccc;
  --background: #fbf3e6;
  // TEXT
  --text-1: #000000;
  --text-2: #333333;
  --text-3: #545454;
  --text-4: #aaaaaa;
  // HIGHLIGHTS
  --highlight-1: #41edb8;
  --highlight-2: #ff8de9;
  --highlight-3: #1627f2;
  --highlight-4: #8086f8;
  // SUCCESS
  --success-1: #5ac73a;
  --success-2: #1ecc24;
  // DANGER
  --danger-1: #ff4b4b;
  --danger-2: #e83333;
  --danger-3: #ffcece;
  // WARNING
  --warning-1: #f7c472;
  // INFO
  --info-1: #1180db;
  --info-2: #3aa1f4;
}
