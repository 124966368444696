.styledborder {
  background: var(--background);
  display: inline-block;
  border: 2px solid var(--text-1);
  position: relative;
  z-index: 50;
  border-radius: 3%;

  &::before {
    background-color: var(--text-1);
    content: "";
    position: absolute;
    width: calc(100% + 5px);
    height: calc(100% + 6px);
    bottom: -5px;
    right: -5px;
    z-index: -1;
    border-radius: 3%;
  }

  &.text .styledborder__body {
    margin: 7px 14px;
  }

  &__container {
    overflow: hidden;
    border-radius: 3%;
    height: 100%;
    background: var(--background);
  }

  &__header {
    height: 17px;
    border-bottom: 2px solid var(--text-1);
    background: var(--background);

    &__btns {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 7px;

      .btn {
        z-index: 1;
      }

      .btn__stack {
        height: 8px;
        width: 8px;
        display: inline-block;
        border-radius: 50%;
        margin-left: 4px;
        border: 1px solid var(--text-1);
        position: relative;

        &__1 {
          background: var(--success-1);
        }

        &__2 {
          background: var(--warning-1);
        }

        &__3 {
          background: var(--danger-1);
        }

        &::before {
          background-color: var(--text-1);
          content: "";
          position: absolute;
          width: calc(100% + 2px);
          height: calc(100% + 2px);
          bottom: -1.5px;
          right: -1.5px;
          z-index: -1;
          border-radius: 50%;
        }
      }
    }
  }

  &__body {
    margin: 7px;
    background: var(--background);
    border-radius: 2%;
    overflow: hidden;
  }

  &.nopadding &__body {
    margin: 0;
  }
}
