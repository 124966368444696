.gridbg {
  z-index: -1;
  background: rgb(54, 70, 255);
  background: linear-gradient(
    360deg,
    rgba(54, 70, 255, 1) 0%,
    rgba(128, 134, 248, 1) 100%
  );
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  &__grid {
    height: 100%;
    width: 100%;
    background-size: 20px 20px;
    background-image: linear-gradient(to right, white 1px, transparent 1px),
      linear-gradient(to bottom, white 1px, transparent 1px);
    opacity: 0.25;
    transition: transform 0.1s ease;
  }
}
