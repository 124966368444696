.scrollspy {
  opacity: 0;

  &.bottom {
    transform: translateY(10px);
  }

  &.top {
    transform: translateY(-10px);
  }

  &.right {
    transform: translateX(10px);
  }

  &.left {
    transform: translateX(-10px);
  }

  &.animated {
    opacity: 1;
    transition: 0.5s ease;
    transform: translate(0, 0);
  }
}
