.page__controls {
  margin-bottom: 40px;
  &__actions {
    display: flex;
    justify-content: space-between;
  }

  .styledborder__body {
    margin: 0;
  }

  span {
    width: 2px;
    border-radius: 0.25px;
    // height: 100%;
    background: var(--elevation-6);
  }

  a {
    background: none;
    border: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    // width: 33px;
    cursor: pointer;
    padding: 6px 10px;
    transition: 0.2s ease;
    @include body-bold;
    color: var(--text);
    font-size: 12px;
    text-transform: uppercase;

    i {
      margin: 0;
      margin-right: 7px;
      line-height: 1em;
      font-size: 17px;
      color: var(--text-1);
    }

    &:hover,
    &:focus,
    &:active {
      background: white;

      i {
        color: #ccc;
      }
    }
  }
}
